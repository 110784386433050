import React from "react";
import { graphql } from 'gatsby'
import MDEditor from '@uiw/react-md-editor';
import Layout from '../components/layout'
import { Link } from 'gatsby'

const Newmainpagetemplate = ({data,location}) =>{
  var Index = location.state != null ? location.state.index : 0;
  console.log(data)
  var neweventtemplate = data.strapiNewEvents.NewEventComponent
  return (
    <Layout>
      <div className="card" style={{boxShadow: '5px 10px 18px #888888',width:"82%"}}>
      <Link to="/event">
      {' '}
      <span style={{ fontSize: '25px' }}>←</span> Back to All events
      </Link>
      <MDEditor.Markdown source={neweventtemplate[Index].PageContent}/>
      </div>
      </Layout>
    )
}

export const query = graphql`
query newevent_template($Slug: String!){
    strapiNewEvents(NewEventComponent: {elemMatch: {link: {eq: $Slug}}}) {
        NewEventComponent {
          link
          PageContent
        }
      }
  } 
`


export default Newmainpagetemplate